import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/index",
    component: () => import("@/views/page/IndexView.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/page/HomeView.vue"),
      },
      {
        path: "/schoolInfoPushSystem",
        name: "校园信息发布系统",
        component: () => import("@/views/page/soft/SchoolInfoPushSystem.vue"),
      },
      // {
      //   path: "/schoolAffairsSystem",
      //   name: "校务发布系统",
      //   component: () => import("@/views/page/soft/SchoolAffairsSystem.vue"),
      // },
      {
        path: "/studentShuttleSystem",
        name: "学生接送系统",
        component: () => import("@/views/page/soft/StudentShuttleSystem.vue"),
      },
      {
        path: "/classBrandSystem",
        name: "班牌多媒体综合管理系统",
        component: () => import("@/views/page/soft/ClassBrandSystem.vue"),
      },
      {
        path: "/schoolAffairsSystem",
        name: "校务管理系统",
        component: () => import("@/views/page/soft/SchoolAffairsSystem.vue"),
      },
      {
        path: "/studentAffairsSystem",
        name: "学务管理系统",
        component: () => import("@/views/page/soft/StudentAffairsSystem.vue"),
      },
      {
        path: "/teachAffairsSystem",
        name: "教务管理系统",
        component: () => import("@/views/page/soft/TeachAffairsSystem.vue"),
      },
      {
        path: "/generalAffairsSystem",
        name: "总务管理系统",
        component: () => import("@/views/page/soft/GeneralAffairsSystem.vue"),
      },
      {
        path: "/CAS",
        name: "统一身份认证平台",
        component: () => import("@/views/page/solution/CAS.vue"),
      },
      {
        path: "/infoPush",
        name: "统一信息发布平台",
        component: () => import("@/views/page/solution/InfoPush.vue"),
      },
      {
        path: "/input",
        name: "统一智能填报平台",
        component: () => import("@/views/page/solution/Input.vue"),
      },
      {
        path: "/dataCore",
        name: "统一数据中心平台",
        component: () => import("@/views/page/solution/DataCore.vue"),
      },
      {
        path: "/handleBusiness",
        name: "网上办事大厅平台",
        component: () => import("@/views/page/solution/HandleBusiness.vue"),
      },
      {
        path: "/infoPortal",
        name: "综合信息门户平台",
        component: () => import("@/views/page/solution/InfoPortal.vue"),
      },
      {
        path: "/dataAnalysis",
        name: "数据分析展示平台",
        component: () => import("@/views/page/solution/DataAnalysis.vue"),
      },
      {
        path: "/wisdomSchool",
        name: "智慧校园管理平台",
        component: () => import("@/views/page/solution/WisdomSchool.vue"),
      },
      {
        path: "/wisdomIOT",
        name: "智慧物联管理平台",
        component: () => import("@/views/page/solution/WisdomIOT.vue"),
      },
	  {
	    path: "/demonstration",
	    name: "产品演示",
	    component: () => import("@/views/page/solution/demonstration.vue"),
	  },
      {
        path: "/index",
        redirect: "/home",
      },
    ],
  },
  {
    path: "/",
    redirect: "/index",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to,from,savePosition){
    document.querySelector("#app").scrollIntoView(true);
  }
});

export default router;
